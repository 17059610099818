<template>
  <div>
    <button-all-header
      :contentBtnAdd="'Thêm mới'"
      :arrayExcel="arrayExcel"
      :showBtnMultiDelete="showBtnMultiDelete"
      :hideExportFile="false"
      :idImport="tab"
      @clickDelete="deleteItems"
      @clickDowloadSample="downloadExampleFileTitle"
      @importFile="importFileExcel"
      @clickAdd="showModalCreate"
      @search="search($event)"
    />
    <b-table-simple
      hover
      small
      caption-top
      responsive
      class="table-simple"
    >
      <colgroup><col><col></colgroup>
      <colgroup><col><col><col></colgroup>
      <colgroup><col><col></colgroup>
      <b-thead>
        <b-tr>
          <b-th>
            <b-form-checkbox
              :checked="isSelectedAll"
              :indeterminate="indeterminateCheckin"
              @change="selectAllRows($event)"
            /></b-th>
          <b-th
            colspan="1"
          >
            TÊN CHỈ TIÊU THỐNG KÊ
          </b-th>
          <b-th
            colspan="1"
          >
            MÃ SỐ
          </b-th>
          <b-th
            class="border-bottom-column"
            colspan="6"
          >
            THEO MỨC ĐỘ THƯƠNG TẬT
          </b-th>
          <b-th
            class="border-bottom-column"
            colspan="6"
          >
            THIỆT HẠI DO TNLĐ
          </b-th>
          <b-th
            colspan="1"
            style="width: 120px"
          >
            CHỨC NĂNG
          </b-th>
        </b-tr>
        <b-tr>
          <b-th></b-th>
          <b-th></b-th>
          <b-th></b-th>
          <b-th
            colspan="3"
            class="border-bottom-column"
          >
            SỐ VỤ TNLĐ
          </b-th>
          <b-th
            colspan="3"
            class="border-bottom-column"
          >
            SỐ NGƯỜI BỊ NẠN TNLĐ
          </b-th>
          <b-th>
            TỔNG SỐ NGÀY NGHỈ VÌ TAI NẠN LAO ĐỘNG
          </b-th>
          <b-th
            colspan="4"
            class="border-bottom-column"
          >
            CHI PHÍ TÍNH BẰNG TIỀN (1.000 Đ)
          </b-th>
          <b-th>
            THIỆT HẠI
            TÀI SẢN
            (1.000 Đ)
          </b-th>
          <b-th></b-th>
        </b-tr>
        <b-tr>
          <b-th></b-th>
          <b-th></b-th>
          <b-th></b-th>
          <b-th>
            TỔNG SỐ
          </b-th>
          <b-th>
            SỐ VỤ CÓ NGƯỜI CHẾT
          </b-th>
          <b-th>
            SỐ VỤ CÓ 2 NGƯỜI BỊ NẠN TRỞ LÊN
          </b-th>
          <b-th>SỐ LAO ĐỘNG NỮ</b-th>
          <b-th>SỐ NGƯỜI CHẾT</b-th>
          <b-th>SỐ NGƯỜI BỊ THƯƠNG NẶNG</b-th>
          <b-th></b-th>
          <b-th>TỔNG SỐ</b-th>
          <b-th
            colspan="3"
            class="border-bottom-column"
          >KHOẢNG CHI CỤC THỂ CỦA CƠ SỞ</b-th>
          <b-th></b-th>
          <b-th></b-th>
        </b-tr>
        <b-tr>
          <b-th></b-th>
          <b-th></b-th>
          <b-th></b-th>
          <b-th></b-th>
          <b-th></b-th>
          <b-th></b-th>
          <b-th></b-th>
          <b-th></b-th>
          <b-th></b-th>
          <b-th></b-th>
          <b-th></b-th>
          <b-th>Y TẾ</b-th>
          <b-th>TRẢ LƯƠNG TRONG THỜI GIAN/ĐIỀU TRỊ</b-th>
          <b-th>BỒI THƯỜNG/TRỢ CẤP</b-th>
          <b-th></b-th>
          <b-th></b-th>
        </b-tr>
      </b-thead>
      <b-tbody
        v-if="dataTable.length > 0"
      >
        <b-tr
          v-for="(item, index) in dataTable"
          :key="index"
        >
          <b-td class="pb-25">
            <b-form-checkbox
              :checked="item.isSelected"
              @input="selectRowTable($event, index)"
            />
          </b-td>
          <b-td class="pb-1 pt-1">
            {{ item.name }}
          </b-td>
          <b-td class="pb-1 pt-1">
            {{ item.code }}
          </b-td>
          <b-td class="pb-1 pt-1">
            {{ item.totalCase }}
          </b-td>
          <b-td class="pb-1 pt-1">
            {{ item.totalDeathCase }}
          </b-td>
          <b-td class="pb-1 pt-1">
            {{ item.total2InjuryCase }}
          </b-td>
          <b-td class="pb-1 pt-1">
            {{ item.totalFemaleWorker }}
          </b-td>
          <b-td class="pb-1 pt-1">
            {{ item.deathWorker }}
          </b-td>
          <b-td class="pb-1 pt-1">
            {{ item.seriouslyInjuryWorker }}
          </b-td>
          <b-td class="pb-1 pt-1">
            {{ item.absent }}
          </b-td>
          <b-td class="pb-1 pt-1">
            {{ item.totalCost }}
          </b-td>
          <b-td class="pb-1 pt-1">
            {{ item.healthCost }}
          </b-td>
          <b-td class="pb-1 pt-1">
            {{ item.salaryCost }}
          </b-td>
          <b-td class="pb-1 pt-1">
            {{ item.indemnifyCost }}
          </b-td>
          <b-td class="pb-1 pt-1">
            {{ item.assetCost }}
          </b-td>
          <b-td class="pb-1 pt-1">
            <feather-icon
              icon="Edit3Icon"
              size="18"
              class="text-body ml-2"
              @click="showModalEdit(item.id)"
            />
            <feather-icon
              icon="Trash2Icon"
              size="18"
              class="text-body ml-2"
              @click="deleteItem(item.id)"
            />
          </b-td>
        </b-tr>
      </b-tbody>

    </b-table-simple>
    <!-- pagination -->
    <my-pagination
      :totalItems="tab === 'Total' ? 0 : totalRecord"
      :currentPage="urlQuery.pageNumber"
      @pageClick="pageChange"
    />
    <div v-if="dataTable.length === 0">
      <div class="empty-table d-block">
        <div class="w-100 d-flex align-items-center justify-content-center">
          <b-img
            class="img-empty-table"
            style="width: 400px"
            fluid
            :src="emptyImg"
            alt="empty table"
          />
        </div>
      </div>
    </div>
    <modal-create-table
      :id="modalReport"
      :title="'Thêm mới'"
      :dataDetail="dataDetail"
      @accept="handleAccept"
    />
    <!-- modal xóa người dùng-->
    <confirm-modal
      :id="confirmModalId"
      :content="modalContent"
      @accept="deleteAction"
    />
  </div>
</template>

<script>
import {
  BTableSimple,
  BTbody,
  BTr,
  BTh,
  BTd,
  BImg,
  BThead,
  BFormCheckbox,
} from 'bootstrap-vue'
import { mapActions, mapGetters } from 'vuex'
import ButtonAllHeader from '@/views/common/ButtonAllHeader.vue'
import MyPagination from '@/components/pagination/MyPagination.vue'
import ConfirmModal from '@/components/confirm-modal/ConfirmModal.vue'
import ModalCreateTable from './ModalCreateTable.vue'
import { axiosApiInstance } from '@/@core/auth/jwt/axiosApiInstance'
import ConstantsApi from '../../constants/ConstantsApi'

export default {

  components: {
    BTableSimple,
    BTbody,
    BTr,
    BTh,
    BTd,
    BThead,
    ButtonAllHeader,
    BFormCheckbox,
    ConfirmModal,
    MyPagination,
    BImg,
    ModalCreateTable,
  },
  props: {
    tab: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      // eslint-disable-next-line global-require
      emptyImg: require('@/assets/images/svg/No-data.gif'),
      modalReport: this.tab,
      showBtnMultiDelete: false,
      arrayExcel: [
        'code',
        'name',
        'totalCase',
        'totalDeathCase',
        'total2InjuryCase',
        'totalWorker',
        'totalFemaleWorker',
        'deathWorker',
        'seriouslyInjuryWorker',
        'absent',
        'healthCost',
        'salaryCost',
        'indemnifyCost',
        'assetCost',
      ],
      idDetail: null,
      dataDetail: {},
      modalContent: '',
      deleteIds: [],
      isIndeterminate: null,
      confirmModalId: `${this.tab}Delete`,
      urlQuery: {
        accidentSpecializeReportId: this.$route.params.id,
        accidentSpecializeTitleType: this.tab,
        key: '',
        pageNumber: 1,
        pageSize: 10,
      },
      totalRecord: 0,
      dataTable: [],
    }
  },
  computed: {
    idCourse() {
      return this.$route.params.id
    },
    isSelectedAll() {
      return !this.dataTable.find(item => !item.isSelected) && this.dataTable.length > 0
    },
    indeterminateCheckin() {
      const checkedRows = this.dataTable.filter(item => item.isSelected)
      return checkedRows.length > 0 && checkedRows.length !== this.dataTable.length
    },
  },
  created() {
    this.getList()
  },
  methods: {
    ...mapActions('accidentSpecializeReport', [
      'downloadExampleFileTitle',
      'getApiExcelTitle',
    ]),

    async getList() {
      const { data } = await axiosApiInstance.get(ConstantsApi.LIST_TITLE, {
        params: this.urlQuery,
      })
      this.dataTable = data?.data?.pageLists
      this.dataTable.forEach(element => {
        element.isSelected = false
      })
      this.totalRecord = data?.data?.totalRecord
    },

    // phân trang
    pageChange(pageNumber, pageSize) {
      this.urlQuery.pageNumber = pageNumber
      this.urlQuery.pageSize = pageSize
      this.getList()
    },
    // Chọn người dùng để thực hiện action
    selectRowTable(val, index) {
      const selectedRow = this.dataTable[index]
      selectedRow.isSelected = val
      this.dataTable.splice(index, 1, selectedRow)
      const rowSelected = this.dataTable.filter(item => item.isSelected === true)
      if (selectedRow) this.showBtnMultiDelete = rowSelected.length > 0
    },
    selectAllRows(val) {
      const cloneRows = this.dataTable
      cloneRows.forEach(item => {
        item.isSelected = val
      })
      this.dataTable = [...cloneRows]
    },

    // xóa một người dùng
    deleteItem(id) {
      this.deleteIds = []
      this.deleteIds.push(id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // click xóa nhiều người dùng
    deleteItems() {
      const tableRows = this.dataTable.filter(item => item.isSelected === true)
      this.deleteIds = tableRows.map(item => item.id)
      this.modalContent = 'Bạn có muốn xóa không?'
      this.$bvModal.show(this.confirmModalId)
    },

    // Xoá người dùng của hệ thống
    async deleteAction() {
      this.$showAllPageLoading()
      const payload = {
        ids: this.deleteIds,
        accidentSpecializeId: this.$route.params.id,
      }
      await axiosApiInstance.post(ConstantsApi.DELETE_TITLE, payload).then(() => {
        this.$hideAllPageLoading()
        this.$bvToast.toast('Xóa thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.getList()
      }).catch(e => {
        this.$hideAllPageLoading()
        this.$bvToast.toast('Xóa không thành công', {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },
    showModalCreate() {
      this.$bvModal.show(this.modalReport)
    },
    async showModalEdit(id) {
      this.idDetail = id
      this.$bvModal.show(this.modalReport)
      const { data } = await axiosApiInstance.get(`${ConstantsApi.DETAIL_TITLE}${id}`)
      this.dataDetail = data
    },
    downloadExampleFile() {
    },

    handleAccept(val) {
      if (!this.idDetail) {
        this.add(val)
      } else {
        this.edit(val)
      }
    },
    add(val) {
      const payload = {
        ...val,
        totalCase: parseInt(val.totalCase, 10),
        totalDeathCase: parseInt(val.totalDeathCase, 10),
        total2InjuryCase: parseInt(val.total2InjuryCase, 10),
        totalWorker: parseInt(val.totalWorker, 10),
        totalFemaleWorker: parseInt(val.totalFemaleWorker, 10),
        deathWorker: parseInt(val.deathWorker, 10),
        seriouslyInjuryWorker: parseInt(val.seriouslyInjuryWorker, 10),
        absent: parseInt(val.absent, 10),
        healthCost: parseInt(val.healthCost, 10),
        salaryCost: parseInt(val.salaryCost, 10),
        indemnifyCost: parseInt(val.indemnifyCost, 10),
        assetCost: parseInt(val.assetCost, 10),
        accidentSpecializeId: this.$route.params.id,
        accidentSpecializeTitleType: this.tab,
      }
      axiosApiInstance.post(ConstantsApi.ADD_TITLE, payload).then(response => {
        this.$bvToast.toast('Thêm loại hình cơ sở thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.getList()
        this.$bvModal.hide(this.modalReport)
      }).catch(e => {
        this.$bvToast.toast(e.response.data.errors[0], {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },
    edit(val) {
      const payload = {
        ...val,
        totalCase: parseInt(val.totalCase, 10),
        totalDeathCase: parseInt(val.totalDeathCase, 10),
        total2InjuryCase: parseInt(val.total2InjuryCase, 10),
        totalWorker: parseInt(val.totalWorker, 10),
        totalFemaleWorker: parseInt(val.totalFemaleWorker, 10),
        deathWorker: parseInt(val.deathWorker, 10),
        seriouslyInjuryWorker: parseInt(val.seriouslyInjuryWorker, 10),
        absent: parseInt(val.absent, 10),
        healthCost: parseInt(val.healthCost, 10),
        salaryCost: parseInt(val.salaryCost, 10),
        indemnifyCost: parseInt(val.indemnifyCost, 10),
        assetCost: parseInt(val.assetCost, 10),
        accidentSpecializeId: this.$route.params.id,
        accidentSpecializeTitleType: this.tab,
        id: this.idDetail,
      }
      axiosApiInstance.post(ConstantsApi.EDIT_TITLE, payload).then(response => {
        this.$bvToast.toast('chỉnh sửa loại hình cơ sở thành công', {
          title: 'Thông báo',
          variant: 'success',
          toaster: this.$toastPosition,
          solid: true,
        })
        this.getList()
        this.$bvModal.hide(this.modalReport)
      }).catch(e => {
        this.$bvToast.toast(e.response.data.errors[0], {
          title: 'Thông báo',
          variant: 'danger',
          toaster: this.$toastPosition,
          solid: true,
        })
      })
    },

    // Thêm dữ liệu bằng file excel
    async importFileExcel(event) {
      if (event) {
        this.getValidata(event)
      }
    },
    async getValidata(dataInput) {
      dataInput.forEach(element => {
        Object.entries(element).forEach(([key, value]) => {
          if (key !== 'code' && key !== 'name') {
            element[key] = value ? parseInt(value, 10) : 0
          }
        })
      })
      const model = {
        accidentSpecializeTitleType: this.tab,
        accidentSpecializeReportId: this.$route.params.id,
        listExcel: dataInput,
        isValidate: true,
      }
      await this.getApiExcelTitle(model)
      await this.$router.push({ name: 'accedent-specialize-title-import', params: { id: this.$route.params.id, type: this.tab } })
    },
  },
}
</script>

<style lang="scss" scoped>

  .table-simple {
  .table {
    thead {
      tr {
        th {
          background-color: rgba(34, 127, 244, 0.12) !important;
        }
      }
    }
  }
}
.table thead th {
  border: unset;
}
.border-bottom-column {
  border-bottom: 1px solid #CBCDD2 !important;
}

</style>
